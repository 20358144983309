/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';

import abbottLogo from 'assets/images/abbott-logo.png';
import bgTitle from 'assets/images/livestream/bg_title.png';
import sparkle from 'assets/images/livestream/question_spark.png';
import bannerDecorMb from 'assets/images/new/home/banner-decorator-mobile.png';
import circleLogo from 'assets/images/new/home/circleLogo.png';
import hospenLogo from 'assets/images/new/home/logo-vietspen.png';
import nhapthongtin from 'assets/images/nhapthongtin.png';
import spark from 'assets/images/spark.png';
import Button from 'components/atoms/Button';
import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';
import { InteractiveAnswer } from 'components/templates/Livestream/Interactive';
import useResumeFg from 'hooks/useResumeFg';
import useWindowDimensions from 'hooks/useWindowDimension';
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from 'services/common/storage';
import { postQuestionInteractiveForGuestService } from 'services/questions';
import { InteractiveItem } from 'services/questions/type';
import { getTokenWebSocketForGuestService } from 'services/websocket';
import socket from 'services/websocket/socket';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getQuestionInteractiveAction, setInteractives } from 'store/questions';
import { setSocketStatus } from 'store/system';
import { LOCALSTORAGE_FIELDNAME, TOTAL_TIME_INTERACTIVE } from 'utils/constant';
import mapModifiers from 'utils/functions';

const InteractiveOffline: React.FC<{ type: number }> = ({ type }) => {
  const { height } = useWindowDimensions();
  const dispatch = useAppDispatch();
  const { interactiveList, currentQuestion } = useAppSelector(
    (state) => state.questions
  );
  const [step, setStep] = useState<number>(0);
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const { host, protocol } = window.location;
    // const wsHost = `${protocol === 'https:' ? 'wss' : 'ws'}://${host}/ws`;
    // dev
    const wsHost = 'wss://hoithaoensure.com/ws';
    socket(wsHost, getTokenWebSocketForGuestService, {
      onMessage: (data: string) => {
        const parsedData = JSON.parse(data);
        if (parsedData.event === 'notifyInteractiveExamAllUser') {
          dispatch(
            setInteractives({
              timeBroadcasted: parsedData.data.timeBroadcasted,
              data: parsedData.data.interactiveActivityQuestion,
            })
          );
        }
      },
      onStateChange: (stt) => {
        dispatch(setSocketStatus(stt));
      },
    });
    return () => { };
  }, []);

  const handleSubmit = () => {
    if (!fullName) {
      setError('Vui lòng nhập họ tên');
      return;
    }
    // eslint-disable-next-line prefer-regex-literals
    if (RegExp(/^(\+\d{1,3}[- ]?)?\d{10}$/).test(phoneNumber) === false) {
      setError('Số điện thoại không hợp lệ');
      return;
    }
    localStorage.setItem('E_122024_USERNAME', fullName);
    localStorage.setItem('E_122024_PHONE', phoneNumber);
    localStorage.setItem('E_122024_USERTYPE', type.toString());
    if (currentQuestion) {
      setStep(2);
    } else {
      setStep(1);
    }
  };

  // interactive
  const [seconds, setSeconds] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState<number | null>(
    getLocalStorage(LOCALSTORAGE_FIELDNAME.INTERACTIVE_ANSWER)
      ? Number(getLocalStorage(LOCALSTORAGE_FIELDNAME.INTERACTIVE_ANSWER))
      : null
  );

  const currentQuestionData = useMemo(
    () => interactiveList.find(
      (item) => item.interactiveActivityQuestion.id === currentQuestion?.id
    ),
    [currentQuestion, interactiveList]
  );

  const handleSelectInteractive = async (
    questionId: number,
    answerId: number
  ) => {
    try {
      await postQuestionInteractiveForGuestService({
        question_id: questionId,
        answer_id: answerId,
        name: fullName || getLocalStorage('E_122024_USERNAME') || '',
        phone: phoneNumber || getLocalStorage('E_122024_PHONE') || '',
      });
    } catch {
      // empty
    }
  };
  useEffect(() => {
    if (seconds > 0) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds > 0) {
            return prevSeconds - 1;
          }
          clearInterval(interval);
          return 0;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
    return () => { };
  }, [seconds]);

  useEffect(() => {
    if (seconds === 0) {
      setSelectedAnswer(null);
      removeLocalStorage(LOCALSTORAGE_FIELDNAME.INTERACTIVE_ANSWER);
    }
  }, [seconds]);

  useEffect(() => {
    if (!currentQuestion) {
      if (
        getLocalStorage('E_122024_USERNAME')
        && getLocalStorage('E_122024_PHONE')
        && Number(getLocalStorage('E_122024_USERTYPE')) === type
      ) {
        setStep(1);
      } else {
        setStep(0);
      }
      return () => { };
    }
    if (currentQuestion.timeBroadcasted === null) {
      return () => { };
    }
    if (
      getLocalStorage('E_122024_USERNAME')
      && getLocalStorage('E_122024_PHONE')
      && Number(getLocalStorage('E_122024_USERTYPE')) === type
    ) {
      setStep(2);
      if (
        currentQuestion.timeBroadcasted
        && currentQuestion.timeBroadcasted > TOTAL_TIME_INTERACTIVE
      ) {
        setSeconds(0);
      } else {
        setSeconds(
          TOTAL_TIME_INTERACTIVE - (currentQuestion.timeBroadcasted || 0)
        );
      }
    } else {
      setStep(0);
    }
    return () => { };
  }, [currentQuestion, type]);

  useEffect(() => {
    dispatch(getQuestionInteractiveAction(type));
  }, [dispatch, type]);

  useResumeFg(() => {
    dispatch(getQuestionInteractiveAction(type));
  });

  useEffect(() => {
    if (
      interactiveList.filter(
        (item) => item.timeBroadcasted && item.timeBroadcasted > 30
      ).length === 4
    ) {
      setStep(3);
    }
  }, [interactiveList]);

  return (
    <div className="p-interactiveOffline">
      {(() => {
        switch (step) {
          case 1:
            return (
              <div className="p-interactiveOffline_waiting">
                <div className="p-interactiveOffline_content">
                  <div className="p-interactiveOffline_logo">
                    <div className="p-interactiveOffline_logo_item p-interactiveOffline_circleImage_wrapper">
                      <img src={circleLogo} alt="" className="p-interactiveOffline_circleImage" />
                    </div>
                    <div className="p-interactiveOffline_logo_item">
                      <Image src={hospenLogo} ratio="342x144" />
                    </div>
                    <div className="p-interactiveOffline_logo_item">
                      <Image src={abbottLogo} ratio="167x76" />
                    </div>
                  </div>
                  <div className="p-interactiveOffline_title">
                    <Text modifiers={['white', 'center', '26x39', 'brandon']}>
                      HỘI THẢO KHOA HỌC
                    </Text>
                  </div>
                  <div className="p-interactiveOffline_sologan">
                    <Text
                      modifiers={[
                        'linearGoldNew',
                        'center',
                        '32x40',
                        '700',
                        'brandonGrotesque',
                        'linearShadow',
                      ]}
                    >
                      TỐI ƯU HÓA DINH DƯỠNG LÂM SÀNG
                    </Text>
                  </div>
                  <div className="p-interactiveOffline_sologan">
                    <Text
                      modifiers={[
                        'linearGoldNew',
                        'center',
                        '32x40',
                        '700',
                        'brandonGrotesque',
                        'linearShadow',
                      ]}
                    >
                      GIÚP NÂNG CAO HIỆU QUẢ ĐIỀU TRỊ
                    </Text>
                  </div>
                  <div className="p-interactiveOffline_sologan">
                    <Text
                      modifiers={[
                        'linearGoldNew',
                        'center',
                        '32x40',
                        '700',
                        'brandonGrotesque',
                        'linearShadow',
                      ]}
                    >
                      Ở BỆNH NHÂN UNG THƯ
                    </Text>
                  </div>

                  <div className="p-interactiveOffline_divider p-interactiveOffline_waiting_divider">
                    <img src={spark} alt="" />
                  </div>

                  <div className="p-interactiveOffline_waiting_title">
                    <div className="p-interactiveOffline_waiting_title_text">
                      <Text modifiers={['white', 'center', '24x36', '700', 'brandon']}>
                        PHẦN TƯƠNG TÁC CÂU HỎI
                      </Text>
                      <Text modifiers={['white', 'center', '24x36', '700', 'brandon']}>
                        SẼ BẮT ĐẦU TRONG ÍT PHÚT NỮA
                      </Text>
                      <Text modifiers={['white', 'center', '24x36', '700', 'brandon']}>
                        QUÝ CHUYÊN VIÊN Y TẾ VUI LÒNG
                      </Text>
                      <Text modifiers={['white', 'center', '24x36', '700', 'brandon']}>
                        CHỜ TRONG GIÂY LÁT
                      </Text>
                    </div>
                  </div>
                  <div className="p-interactiveOffline_waiting_decor">
                    <img src={bannerDecorMb} alt="abbott" />
                  </div>
                </div>
              </div>
            );
          case 2:
            return (
              <div className="t-livestream_interactive">
                <div className="t-livestream_interactive_header">
                  <div className="t-livestream_interactive_note">
                    <Text modifiers={['16x22', 'brandon']}>
                      Thông tin khoa học dành cho cán bộ y tế
                      <br />
                      ENS-H-846-24
                    </Text>
                  </div>
                  <div className="t-livestream_interactive_countdown">
                    <div className="t-livestream_interactive_countdown-text">
                      THỜI&nbsp;GIAN
                      <br />
                      TRẢ&nbsp;LỜI
                    </div>
                    <div className="t-livestream_interactive_countdown-line" />
                    <div className="t-livestream_interactive_countdown-number">
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </div>
                  </div>
                </div>
                {currentQuestionData && (
                  <>
                    <div className="t-livestream_interactive_question">
                      <div className="t-livestream_interactive_question-title">
                        <Text modifiers={['usBlue', '700', 'center']}>
                          Câu hỏi
                          {' '}
                          {
                            currentQuestionData.interactiveActivityQuestion
                              ?.display_order
                          }
                        </Text>
                      </div>
                      <div className={mapModifiers('t-livestream_interactive_question_wrapper', seconds === 0 && 'full')}>
                        <Text modifiers={['white', '700', 'center']}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                currentQuestionData.interactiveActivityQuestion
                                  ?.content || '',
                            }}
                          />
                        </Text>
                        <img
                          src={sparkle}
                          alt="question-decor"
                          className="t-livestream_interactive_question_questionSparkles"
                        />
                        <div className="t-livestream_interactive_question_btmDeco" />
                      </div>
                    </div>
                    <div className="t-livestream_interactive_answers">
                      {currentQuestionData.interactiveActivityQuestion?.answers.map(
                        (answer, index) => (
                          <InteractiveAnswer
                            key={answer.id}
                            id={answer.id}
                            alpha={String.fromCharCode(
                              index + 97
                            ).toUpperCase()}
                            text={answer.content}
                            selected={
                              seconds > 0 && selectedAnswer === answer.id
                            }
                            showCorrect={seconds === 0}
                            corrected={seconds === 0 && answer.is_correct === 1}
                            handleSelected={(id) => {
                              if (seconds > 0) {
                                setSelectedAnswer(id);
                                setLocalStorage(
                                  LOCALSTORAGE_FIELDNAME.INTERACTIVE_ANSWER,
                                  id.toString()
                                );
                                if (currentQuestion?.id) {
                                  handleSelectInteractive(
                                    currentQuestion?.id,
                                    id
                                  );
                                }
                              }
                            }}
                          />
                        )
                      )}
                    </div>
                    {seconds === 0 && (
                      <div className="t-livestream_interactive_noted">
                        <Text modifiers={['white', '500']}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                currentQuestionData.interactiveActivityQuestion
                                  ?.note || '',
                            }}
                          />
                        </Text>
                      </div>
                    )}
                  </>
                )}
              </div>
            );
          case 3:
            return null;
          default:
            return (
              <div
                className="p-interactiveOffline_interactive"
                style={{ minHeight: `${height}px` }}
              >
                <div className="p-interactiveOffline_content">
                  <div className="p-interactiveOffline_logo">
                    <div className="p-interactiveOffline_logo_item p-interactiveOffline_circleImage_wrapper">
                      <img src={circleLogo} alt="" className="p-interactiveOffline_circleImage" />
                    </div>
                    <div className="p-interactiveOffline_logo_item">
                      <Image src={hospenLogo} ratio="342x144" />
                    </div>
                    <div className="p-interactiveOffline_logo_item">
                      <Image src={abbottLogo} ratio="167x76" />
                    </div>
                  </div>
                  <div className="p-interactiveOffline_title">
                    <Text modifiers={['white', 'center', '26x39', 'brandon']}>
                      HỘI THẢO KHOA HỌC
                    </Text>
                  </div>
                  <div className="p-interactiveOffline_sologan">
                    <Text
                      modifiers={[
                        'linearGoldNew',
                        'center',
                        '32x40',
                        '700',
                        'brandonGrotesque',
                        'linearShadow',
                      ]}
                    >
                      TỐI ƯU HÓA DINH DƯỠNG LÂM SÀNG
                    </Text>
                  </div>
                  <div className="p-interactiveOffline_sologan">
                    <Text
                      modifiers={[
                        'linearGoldNew',
                        'center',
                        '32x40',
                        '700',
                        'brandonGrotesque',
                        'linearShadow',
                      ]}
                    >
                      GIÚP NÂNG CAO HIỆU QUẢ ĐIỀU TRỊ
                    </Text>
                  </div>
                  <div className="p-interactiveOffline_sologan">
                    <Text
                      modifiers={[
                        'linearGoldNew',
                        'center',
                        '32x40',
                        '700',
                        'brandonGrotesque',
                        'linearShadow',
                      ]}
                    >
                      Ở BỆNH NHÂN UNG THƯ
                    </Text>
                  </div>

                  <div className="p-interactiveOffline_divider">
                    <img src={spark} alt="" />
                  </div>

                  <div className="p-interactiveOffline_bottom_title">
                    <Image src={bgTitle} alt="" ratio="518x175" />
                    <div className="p-interactiveOffline_bottom_title_text">
                      <Text
                        modifiers={[
                          'white',
                          'center',
                          '32x40',
                          '700',
                          'brandonGrotesque',
                        ]}
                      >
                        XIN MỜI QUÝ CHUYÊN VIÊN Y TẾ
                      </Text>
                      <Text
                        modifiers={[
                          'white',
                          'center',
                          '32x40',
                          '700',
                          'brandonGrotesque',
                        ]}
                      >
                        THAM GIA PHẦN TƯƠNG TÁC
                      </Text>
                    </div>
                  </div>
                  <div className="p-interactiveOffline_bottom_desc">
                    <Text modifiers={['white', 'center', '26x39', 'brandon']}>
                      Vui lòng nhập thông tin bên dưới để tham gia:
                    </Text>
                  </div>
                  <div className="p-interactiveOffline_form">
                    <Text modifiers={['white', 'center', '26x39', 'brandon']}>
                      Họ và tên*
                    </Text>
                    <div className="p-interactiveOffline_input">
                      <input
                        value={fullName}
                        onChange={(e) => {
                          setFullName(e.target.value);
                          setError('');
                        }}
                      />
                    </div>
                    <Text modifiers={['white', 'center', '26x39', 'brandon']}>
                      Số điện thoại*
                    </Text>
                    <div className="p-interactiveOffline_input">
                      <input
                        value={phoneNumber}
                        onChange={(e) => {
                          setPhoneNumber(e.target.value);
                          setError('');
                        }}
                      />
                    </div>
                    {error && (
                      <Text
                        modifiers={['sinopia', 'center', '26x39', 'brandon']}
                      >
                        {error}
                      </Text>
                    )}
                    <div className="p-interactiveOffline_bottom_button">
                      <Button type="submit" onClick={() => handleSubmit()}>
                        <img src={nhapthongtin} alt="" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            );
        }
      })()}
    </div>
  );
};

export default InteractiveOffline;
